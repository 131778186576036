<!-- 
	Copyright 2023 HALBY
	See LICENSE file for details.
 -->

<script lang="ts">
import Button from './Button.svelte'
export let align: "left" | "right" | "top" | "bottom";
export let backgroundColor = '#ffffff';
export let triangleWidth = '15px';
export let triangleHeight = '20px';
export let triangleOffset = '0px';
export let isTriangleActive = true;

let direction: string;
if (align === "left" || align === "right") {
    direction = "horizontal"
} else if (align === "top" || align === "bottom") {
    direction = "vertical"
}

$: triangleStyle = `
    --balloon-bg-color: ${backgroundColor};
    --triangle-width: ${triangleWidth};
    --triangle-height: ${triangleHeight};
    --triangle-offset: ${triangleOffset};
    display: ${isTriangleActive ? 'block' : 'none'};`
</script>

<div class="balloon-block {direction}" style="--balloon-bg-color: {backgroundColor};">
    {#if align === 'left' || align === 'top'}
        <div class="triangle {align}" style={triangleStyle}></div>
    {/if}
    <div class="balloon">
        <slot></slot>
    </div>
    {#if align === 'right' || align === 'bottom'}
        <div class="triangle {align}" style={triangleStyle}></div>
    {/if}
</div>

<style lang="scss">
.balloon-block {
    display: flex;
    flex-basis: auto;
    flex-grow: 1;
    align-items: center;
    &.vertical {
        flex-direction: column;
    }
}
.triangle {
    background-color: var(--balloon-bg-color);
    width: var(--triangle-width, 15px);
    height: var(--triangle-height, 20px);
    transform: translateY(var(--triangle-offset, 0px));
    &.left {
        clip-path: polygon(0 50%, 100% 0%, 100% 100%);
    }
    &.right {
        clip-path: polygon(100% 50%, 0% 0%, 0% 100%);
    }
    &.top {
        clip-path: polygon(0 100%, 100% 100%, 50% 0);
    }
    &.bottom {
        clip-path: polygon(0% 0%, 50% 100%, 100% 0);
    }
}
.balloon {
    position: relative;
    display: block;
    width: 100%;
    background-color: var(--balloon-bg-color);
    flex-basis: auto;
    border-radius: var(--balloon-radius, 10px);
    height: 100%;
}
</style>